export default {
    state: {
        menu: [
            { product: true, title: 'Help Desk', bgcolor: 'primary' },
            {
                title: 'Queries', icon: 'mdi-help-rhombus-outline', href: '/helpdesk/queries',
            },
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}